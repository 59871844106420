/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
import React, { useState, useContext, useEffect } from 'react';
import Router, { useRouter } from 'next/router';
import Head from 'next/head';
import dynamic from 'next/dynamic';
import useSWR from 'swr';
import {
	Container, Row, Col, Input, Button, Table, Modal, ModalHeader, ModalBody, ModalFooter, UncontrolledTooltip, FormFeedback, FormGroup,
	Collapse, Card, CardBody, Popover, PopoverHeader, PopoverBody, UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem,
	Alert, Toast, ToastHeader, ToastBody,
} from 'reactstrap';
import Decimal from 'decimal.js';
import { FontAwesomeIcon as Fa } from '@fortawesome/react-fontawesome';
import {
	faRuler, faPrint, faCheck, faInfoCircle, faTimes, faClock, faSpinner,
} from '@fortawesome/free-solid-svg-icons';
import dayjs from 'dayjs';
import ReactPlayer from 'react-player';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { ContextStore } from 'contexts';
import axios from 'utils/axios';
import { SCORE_STATUS, STUDENT_SITUATION, USER_GROUP } from 'utils/constant';
import round2 from 'utils/round2';

const DefaultPage = () => {
	const { state: { user: userState }, uDispatch } = useContext(ContextStore);
	const router = useRouter();
	const { query, isReady } = router;
	const swal = withReactContent(Swal);
	const [currentDept, setCurrentDept] = useState(query.d || (userState.depts ? userState.depts[0] : ''));
	const { data: studentData, error: errStudents, mutate } = useSWR(
		userState.depts && userState.depts.includes(currentDept) ? `/api/v1/students/${currentDept}` : null, {
			refreshInterval: 800,
		},
	);
	const { data: deptData, error: errDept } = useSWR(userState.depts && userState.depts.includes(currentDept) ? `/api/v1/dept/${currentDept}` : null);
	const [scores, _setScores] = useState([]);
	const [comments, _setComments] = useState([]);
	const [status, setStatus] = useState([]);
	const [popover, setPopover] = useState(-1);
	const { data: allDepts = [], error: errDepts } = useSWR('/api/v1/depts');
	const [showVideo, setShowVideo] = useState(false);
	const [showColor, setShowColor] = useState(false);
	const [showComment, setShowComment] = useState(false);
	const { data: optRule } = useSWR('/api/v1/option/rule');
	const [deptSeeTeacher, setDeptSeeTeacher] = useState(null);
	const { data: isNdaOn } = useSWR('/api/v1/option/nda');
	const { data: ndaContent } = useSWR('/api/v1/ndacontent');
	const { data: optMaintenance } = useSWR('/api/v1/option/maintenance');
	const [scoreSubmitLoading, setScoreSubmitLoading] = useState([]);
	const [submitAllLoading, setSubmitAllLoading] = useState(false);
	const [showSubmittedBadge, setShowSubmittedBadge] = useState(false);
	const [tempUserGroup, setTempUserGroup] = useState(userState?.group);

	const agreeTos = async () => {
		// setModal(false);
		const { data: d } = await axios.post('/api/v1/user/agreetos');
		uDispatch({ type: 'AGREETOS', payload: { nda: new Date(d) } });
	};

	useEffect(() => {
		if (optMaintenance === 'true' || optMaintenance === true) {
			router.replace('/maintenance');
		}
	}, [optMaintenance]);

	useEffect(() => {
		if (!isReady) return;
		if (!userState || Object.keys(userState).length === 0) {
			Router.replace('/login');
		} else if (userState.group >= USER_GROUP.ADMIN) {
			Router.replace('/admin');
		} else if (userState.group >= USER_GROUP.DEPT && (query.preview !== 'd' && query.proxy === undefined)) {
			Router.replace('/dept');
		}

		if (userState.group === USER_GROUP.DEPT && query.proxy !== undefined) {
			setTempUserGroup(USER_GROUP.TEACHER);
		}
	}, [query, isReady]);

	useEffect(() => {
		if (query.d && query.d !== '') {
			if (query.d !== currentDept) {
				_setScores([]);
				setStatus([]);
			}
			setCurrentDept(query.d);
		}
	}, [query]);

	useEffect(() => {
		if (userState.depts && !userState.depts.includes(currentDept)) {
			Router.replace('/');
		}
	}, [currentDept]);

	const setComments = (i, value) => {
		const copy = [...comments];
		copy[i] = value;
		_setComments(copy);
	};

	const setTeacherScore = (scoreAuthor) => {
		const sts = [];
		let scs = [];
		const cms = [];
		scs = studentData.map((x, i) => {
			if (x.comment) {
				const c = x.comment.find((co) => co.author === scoreAuthor);
				if (c) {
					cms[i] = c.comment;
				}
			}

			if (x.status && x.status.length) {
				// HERE DISABLE DIFFSCORE
				// const DISABLE_DIFFSCORE_STATUS = x.status.map((s) => (s === SCORE_STATUS.CHECKED_FAILED ? SCORE_STATUS.CHECKED_SUCCESS : s));
				// sts.push(DISABLE_DIFFSCORE_STATUS);
				sts.push(x.status);
			} else if (x.scores.find((s) => s.author === scoreAuthor)) {
				sts.push(Array(deptData.scores.length).fill(SCORE_STATUS.TEMP));
			} else {
				sts.push(Array(deptData.scores.length).fill(-1));
			}

			if (tempUserGroup === USER_GROUP.TEACHER) {
				if (scores[i] && scores[i].filter((s) => s !== '' && s >= 0).length > 0) {
					return scores[i];
				}
			}
			if (!x.scores.length) return Array(deptData.scores.length).fill('');

			const theScores = Array.from(x.scores).reverse().find((ss) => ss.author === scoreAuthor) || {};
			const {
				author, ip, createdAt, ...d
			} = theScores;
			return Object.values(d);
		});
		_setScores(scs);
		_setComments(cms);
		setStatus(sts);
	};

	useEffect(() => {
		if (!studentData || !deptData) return;
		if (studentData[0].depts !== deptData.name || deptData.name !== currentDept) return;
		if (tempUserGroup === USER_GROUP.TEACHER || scores.length === 0) {
			setTeacherScore(query?.proxy ?? userState._id);
		}
	}, [studentData, deptData]);

	const setScores = (i, j, value) => {
		const copy = [...scores];
		// eslint-disable-next-line no-bitwise
		let v = parseFloat(value);
		if (Decimal(v).decimalPlaces() > 2) {
			v = Decimal(v).toDecimalPlaces(2, Decimal.ROUND_DOWN).toNumber();
		}
		// console.log(Decimal(v).decimalPlaces());
		copy[i][j] = v;
		_setScores(copy);
	};

	const changeGroup = (x) => {
		let d = x;
		if (query.preview) {
			d += `&preview=${query.preview}`;
		}
		window.location = `/?d=${d}`;
	};

	const submitScore = async (id, i, hideAlert = false) => {
		setScoreSubmitLoading((prev) => {
			const copy = [...prev];
			copy[i] = true;
			return copy;
		});
		const s = scores[i].map((x) => parseFloat(x));
		if (s.filter((x) => !Number.isNaN(x) && x >= 0 && x <= 100).length < deptData.scores.length) {
			if (hideAlert !== true) {
				await swal.fire({
					title: '無法送出分數',
					text: '請確定已輸入該位考生所有成績，且所有成績介於 0~100 分',
					icon: 'error',
				});
			}
			setScoreSubmitLoading((prev) => {
				const copy = [...prev];
				copy[i] = false;
				return copy;
			});
			return;
		}
		const scoreData = {};
		deptData.scores.forEach((scoreName, j) => {
			scoreData[scoreName] = s[j];
		});
		const headers = {};
		if (query.proxy !== undefined) {
			headers['x-proxy-user'] = query.proxy;
		}
		await axios.post(`/api/v1/student/${id}`, { scores: scoreData }, { headers });
		mutate();
		setScoreSubmitLoading((prev) => {
			const copy = [...prev];
			copy[i] = false;
			return copy;
		});
		// setScoreCount(i, data.scoreCount);
		// data.status.map((x, j) => {
		// setStatus(i, j, x);
		// });
	};

	const resetScore = async (id, i) => {
		setScores(i, Array(deptData.scores.length).fill(0));
		const scoreData = {};
		deptData.scores.forEach((scoreName) => {
			scoreData[scoreName] = 0;
		});
		await axios.post(`/api/v1/student/${id}`, { scores: scoreData });
		mutate();
	};

	const showTeacher = async (id) => {
		if (userState.group !== USER_GROUP.DEPT) return;
		if (id === 'all') {
			setDeptSeeTeacher(null);
		} else {
			setTeacherScore(id);
			setDeptSeeTeacher(id);
		}
	};

	const submitComment = async (i) => {
		const comment = comments[i];
		await axios.post(`/api/v1/comment/${studentData[i]._id}`, { comment });
		mutate();
	};

	const submitAll = async () => {
		setSubmitAllLoading(true);
		await Promise.allSettled(studentData.map((x, i) => submitScore(x._id, i, true)));
		setSubmitAllLoading(false);
		setShowSubmittedBadge(true);
		setTimeout(() => {
			setShowSubmittedBadge(false);
		}, 3000);
	};

	const bgColor = (i, j) => {
		if (!status[i]) return 'inherit';
		switch (status[i][j]) {
		case SCORE_STATUS.TEMP: return 'lightyellow';
		case SCORE_STATUS.CHECKED_SUCCESS: return 'lightgreen';
		case SCORE_STATUS.CHECKED_FAILED: return 'pink';
		default: return 'inherit';
		}
	};

	const bgValue = (i, j) => {
		if (!status[i]) return '';
		switch (status[i][j]) {
		case SCORE_STATUS.TEMP: return (
			<>
				<Fa icon={faClock} />
				<UncontrolledTooltip target={`scoreInput-${i}-${j}`}>
					正在等待進入差分檢核
				</UncontrolledTooltip>
			</>
		);
		case SCORE_STATUS.CHECKED_SUCCESS: return (
			<>
				<Fa icon={faCheck} />
				<UncontrolledTooltip target={`scoreInput-${i}-${j}`}>
					已通過差分檢核
				</UncontrolledTooltip>
			</>
		);
		case SCORE_STATUS.CHECKED_FAILED: return (
			<>
				<Fa icon={faTimes} />
				<UncontrolledTooltip target={`scoreInput-${i}-${j}`}>
					尚未通過差分檢核
				</UncontrolledTooltip>
			</>
		);
		default: return (
			<>
				<Fa icon={faClock} />
				<UncontrolledTooltip target={`scoreInput-${i}-${j}`}>
					正在等待進入差分檢核
				</UncontrolledTooltip>
			</>
		);
		}
	};

	const printMe = (sc = false) => {
		setShowComment(sc);
		window.scrollTo(0, 0);
		setTimeout(() => {
			window.print();
			setShowComment(false);
		}, 1000);
	};

	useEffect(() => {
		if (!isReady) return 0;
		if (errStudents || errDept || errDepts) {
			const error = errStudents || errDept || errDepts;
			switch (error?.response?.status) {
			case 401:
				Router.replace('/login');
				break;
			default:
				return (
					<h3>
						發生錯誤（
						{error?.response?.status}
						）
					</h3>
				);
			}
		}
		return () => {};
	}, [errStudents, errDept, errDepts, isReady]);

	return (
		<>
			<Head>
				<title>高大評分登錄系統</title>
			</Head>
			{ userState && Object.keys(userState).length > 0 && (tempUserGroup === USER_GROUP.TEACHER || userState.group === USER_GROUP.DEPT) ? (
				<>
					<Container fluid className="app">
						<Row className="pt-5 hide-on-print">
							<Col>
								{ tempUserGroup === USER_GROUP.TEACHER ? (
									<div className="float-right">
										{userState.name || userState.user}
										{query?.proxy !== undefined ? ' (代)' : ''}
										{' '}
										老師
										您好，
										<a href="/login" onClick={() => { uDispatch({ type: 'LOGOUT' }); }}>
											登出
										</a>
										{query?.proxy !== undefined ? (
											<>
												{' | '}
												<a href="/dept" onClick={() => { Router.replace('/dept'); }}>
													回系秘首頁
												</a>
											</>
										) : null}
									</div>
								) : null }
								<h2>成績登錄</h2>
								<div className="clearfix" />
							</Col>
						</Row>
						<Row className="mt-2 hide-on-print">
							<Col sm={4} xs={12}>
								<Input type="select" onChange={(e) => { changeGroup(e.target.value); }} value={currentDept} disabled={query?.proxy !== undefined}>
									{userState.depts.map((x) => (
										<option key={x} value={x}>
											{x}
											{query?.proxy !== undefined ? ' (代理評分)' : ''}
										</option>
									))}
								</Input>
							</Col>
							<Col>
								<Row className="justify-content-end">
									{ tempUserGroup === USER_GROUP.TEACHER ? (
										<>
											{/* <Col sm={2}>
												<Button color="secondary" className="w-100 mb-1" onClick={() => setShowVideo(true)}>
													<span className="mr-1">
														<Fa icon={faQuestionCircle} />
													</span>
													說明
												</Button>
											</Col> */}
											<Col sm={3}>
												{optRule ? (
													<a
														className="btn btn-secondary w-100"
														color="secondary"
														target="_blank"
														rel="noreferrer"
														href={`${process.env.NEXT_PUBLIC_API_PATH}/api/v1/rules/${currentDept}.pdf?t=${dayjs().unix()}`}
													>
														<span className="mr-1">
															<Fa icon={faRuler} />
														</span>
														顯示尺規
													</a>
												) : null}
											</Col>
											<Col sm={3}>
												<UncontrolledButtonDropdown>
													<Button color="secondary" className="mb-1" onClick={() => printMe(true)}>
														<span className="mr-1">
															<Fa icon={faPrint} />
														</span>
														列印
													</Button>
													<DropdownToggle split color="secondary" className="mb-1" />
													<DropdownMenu>
														<DropdownItem onClick={() => printMe(false)}>
															<span className="mr-1">
																<Fa icon={faPrint} />
															</span>
															列印不含備註的成績單
														</DropdownItem>
													</DropdownMenu>
												</UncontrolledButtonDropdown>
											</Col>
										</>
									) : (
										<>
											<Col sm={3}>
												{deptSeeTeacher ? (
													<UncontrolledButtonDropdown>
														<Button color="secondary" className="mb-1" onClick={() => printMe(true)}>
															<span className="mr-1">
																<Fa icon={faPrint} />
															</span>
															列印
														</Button>
														<DropdownToggle split color="secondary" className="mb-1" />
														<DropdownMenu>
															<DropdownItem onClick={() => printMe(false)}>
																<span className="mr-1">
																	<Fa icon={faPrint} />
																</span>
																列印不含備註的成績單
															</DropdownItem>
														</DropdownMenu>
													</UncontrolledButtonDropdown>
												) : null}
											</Col>
											<Col sm={3}>
												<Input type="select" onChange={(e) => showTeacher(e.target.value)}>
													<option value="all">全部檢視</option>
													{deptData?.teachersAll?.map((u) => (
														<option value={u._id} key={u._id}>{u.name}</option>
													))}
												</Input>
											</Col>
										</>
									) }
								</Row>
							</Col>
						</Row>
						<Row className="mt-1 / hide-on-print">
							<Col className="text-muted border-top py-2">
								<button
									type="button"
									className="font-weight-bold border-0 bg-white text-muted"
									onClick={() => setShowColor(!showColor)}
								>
									{showColor ? '▼ 隱藏' : '▶ 顯示'}
									顏色說明
								</button>
								<Collapse isOpen={showColor}>
									<Card>
										<CardBody>
											<ul>
												<li>
													<div style={{ backgroundColor: 'yellow', display: 'inline-block' }} className="px-2"> 黃底 </div>
													表示還有審查委員未完成評分，無差分檢核
												</li>
												<li>
													<div style={{ backgroundColor: 'pink', display: 'inline-block' }} className="px-2"> 紅底 </div>
													表示未通過差分檢核
												</li>
												<li>
													<div style={{ backgroundColor: 'lightgreen', display: 'inline-block' }} className="px-2"> 綠底 </div>
													表示已通過差分檢核
												</li>
												<li>
													<div className="border border-primary d-inline-block px-2"> 藍色框框 </div>
													（若有的話）表示為加分項目，請確認最高加分分數
												</li>
											</ul>
										</CardBody>
									</Card>
								</Collapse>
							</Col>
						</Row>
						<Row className="mt-2">
							{ deptData && studentData && (
								<>
									{deptData.disabled ? (
										<Col sm={12} className="text-center">
											<Alert color="danger" style={{ fontSize: '1.5rem' }}>
												<Fa icon={faTimes} />
												{' '}
												本系組尚未開放填寫，請點擊左上角選單選擇正確的系組。若有疑問，請聯絡系辦。
											</Alert>
										</Col>
									) : null}
									<Table>
										<thead>
											<tr className="trow">
												<th className="sticky-top / bg-that">流水號</th>
												<th className="sticky-top / bg-that">系組</th>
												<th className="sticky-top / bg-that">身分別</th>
												<th className="sticky-top / bg-that">資料狀況</th>
												<th className="sticky-top / bg-that">准考證號碼</th>
												<th className="sticky-top / bg-that">姓名</th>
												{deptData.scores && deptData.scores.map((x) => (
													<th className="sticky-top / bg-that" key={x} style={{ maxWidth: `${70}px` }}>{x}</th>
												))}
												<th className="sticky-top / bg-that">總分</th>
												{ tempUserGroup === USER_GROUP.TEACHER ? (
													<>
														<th className="sticky-top / bg-that hide-on-print">確認送出</th>
														<th className="sticky-top / bg-that hide-on-print">備註</th>
													</>
												) : null }
												<th className="sticky-top / bg-that hide-on-print">已評分</th>
											</tr>
										</thead>
										<tbody>
											{scores.length && status.length && studentData.map((x, i) => (
												<React.Fragment key={x._id}>
													<tr key={`data_${x._id}`} className="trow">
														<td>{i + 1}</td>
														<td>{currentDept}</td>
														<td>{x.type}</td>
														<td>
															{(() => {
																switch (x.situation) {
																case STUDENT_SITUATION.LACK_DATA: return <div className="text-danger">缺資料</div>;
																case STUDENT_SITUATION.NO_DATA: return <div className="text-danger">沒資料</div>;
																case STUDENT_SITUATION.MISSING: return <div className="text-danger">缺考</div>;
																case STUDENT_SITUATION.REFUND: return <div className="text-danger">退費</div>;
																case STUDENT_SITUATION.UNQUALIFIED: return <div className="text-danger">資格不符</div>;
																default: return <div>正常</div>;
																}
															})()}
														</td>
														<td>{x.regNo}</td>
														<td>{x.name}</td>
														{deptData.scores && deptData.scores.map((_, j) => (
															// eslint-disable-next-line react/no-array-index-key
															<td style={{ maxWidth: `${50}px` }} key={`${x._id}${j}`}>
																{tempUserGroup === USER_GROUP.TEACHER ? (
																	<FormGroup className="position-relative mb-0">
																		<Input
																			type="number"
																			min="0"
																			max="100"
																			className={`input ${deptData.ratio[j] === 1 ? 'border border-primary' : null}`}
																			step="0.01"
																			autoComplete="off"
																			style={{ backgroundColor: bgColor(i, j) }}
																			value={scores[i] && scores[i][j] >= 0 ? scores[i][j] : ''}
																			onChange={(e) => { setScores(i, j, e.target.value); }}
																			onWheel={(e) => e.target.blur()}
																			disabled={allDepts.find((d) => d.name === currentDept)?.locked === true
																			|| (userState.group !== USER_GROUP.TEACHER && query?.proxy === undefined) || deptData.disabled === true}
																			invalid={deptData.mode === 'fifth'
																				? (scores[i] && (scores[i][j] < 0 || scores[i][j] > 5))
																				: (scores[i] && (scores[i][j] < 0 || scores[i][j] > 100))}
																		/>
																		<FormFeedback tooltip>
																			分數必須介於 0~
																			{deptData.mode === 'fifth' ? 5 : 100}
																			之間
																		</FormFeedback>
																	</FormGroup>
																) : (
																	<div
																		style={{ backgroundColor: bgColor(i, j) }}
																		id={`scoreInput-${i}-${j}`}
																		className="text-center text-muted border rounded-sm"
																	>
																		{deptSeeTeacher ? (
																			<Input
																				type="number"
																				min="0"
																				max="200"
																				className={`input ${deptData.ratio[j] === 1 ? 'border border-primary' : null}`}
																				step="0.01"
																				autoComplete="off"
																				style={{ backgroundColor: bgColor(i, j) }}
																				value={scores[i] && scores[i][j] >= 0 ? scores[i][j] : ''}
																				disabled
																			/>
																		) : <>{bgValue(i, j)}</>}
																	</div>
																)}
															</td>
														))}
														<td>
															{userState?.group === USER_GROUP.DEPT && deptSeeTeacher === null && !query.proxy ? (
																<div className="text-info">N/A</div>
															) : null}
															{
																scores[i] ? (
																	(scores[i].filter((s) => typeof s === typeof 0 && s >= 0).length === deptData.scores.length
																		&& round2(scores[i].reduce((acc, s, j) => acc + s * deptData.ratio[j], 0)))
																) : ''
															}
															{
																deptData.mode === 'fifth' && scores[i] ? (
																	<>
																		{(() => {
																			const score = scores[i] ? (
																				(scores[i].filter((s) => typeof s === typeof 0 && s >= 0).length === deptData.scores.length
																					&& round2(scores[i].reduce((acc, s, j) => acc + s * deptData.ratio[j], 0)))
																			) : '';
																			if (score === '' || score === 0) return '';
																			if (!score) return '';
																			const delta = (deptData.maxScore - deptData.minScore) / 5;
																			return (
																				<div>
																					<small>
																						換算分數：
																						{deptData.minScore + delta * (score - 1 + 0.5)}
																					</small>
																				</div>
																			);
																		})()}
																	</>
																) : ''
															}
														</td>
														{ tempUserGroup === USER_GROUP.TEACHER ? (
															<>
																<td className="hide-on-print">
																	<Button
																		color="primary"
																		onClick={() => { submitScore(x._id, i); }}
																		disabled={allDepts.find((d) => d.name === currentDept)?.locked === true || deptData.disabled === true}
																	>
																		{scoreSubmitLoading[i] ? <Fa className="fa-spin" icon={faSpinner} /> : '送出'}
																	</Button>
																	<Button
																		className="ml-2"
																		size="sm"
																		color="danger"
																		onClick={() => { resetScore(x._id, i); }}
																		disabled={allDepts.find((d) => d.name === currentDept)?.locked === true || deptData.disabled === true}
																	>
																		歸零
																	</Button>
																</td>
																<td className="hide-on-print">
																	<Button
																		color="secondary"
																		outline={!(comments[i] && comments[i].length)}
																		id={`btnComment${i}`}
																		onClick={() => setPopover(i)}
																	>
																		{comments[i] && comments[i].length ? '修改' : '備註' }
																	</Button>
																</td>
															</>
														) : null }
														<td className="hide-on-print">
															{x.scoresCount}
															/
															{ deptData.teachers }
														</td>
													</tr>
													{showComment && comments[i] && comments[i].length ? (
														<tr key={`comment_${x._id}`}>
															<td colSpan={2}>↳ 註記</td>
															<td colSpan={6} className="text-left pl-1"><small>{comments[i]}</small></td>
														</tr>
													) : null}
												</React.Fragment>
											))}
										</tbody>
									</Table>
									{tempUserGroup === USER_GROUP.TEACHER && scores.length && status.length && studentData.map((x, i) => (
										<Popover placement="left" isOpen={popover === i} target={`btnComment${i}`} toggle={() => setPopover(-1)}>
											<PopoverHeader>特殊情況註記</PopoverHeader>
											<PopoverBody>
												<Input
													type="textarea"
													cols={85}
													rows={8}
													value={comments[i]}
													onChange={(e) => setComments(i, e.target.value)}
													onBlur={() => submitComment(i)}
												/>
												<div className="text-muted">
													請輸入有關
													{studentData[i].name}
													的特殊情況註記。輸入完後自動儲存，於列印時一併顯示。但
													<strong>不會被其他審查委員看到。</strong>
												</div>
											</PopoverBody>
										</Popover>
									))}
								</>
							)}
						</Row>
						<Row className="mb-5 / hide-on-print">
							<Col sm={12}>
								{ tempUserGroup === USER_GROUP.TEACHER ? (
									<Button
										color="primary"
										className="w-100"
										onClick={submitAll}
										disabled={(
											submitAllLoading
											|| allDepts.find((d) => d.name === currentDept)?.locked === true
											|| deptData?.disabled === true
										)}
									>
										{!submitAllLoading ? (
											<>
												<span className="mr-1"><Fa icon={faCheck} /></span>
												全部送出
											</>
										) : (
											<>
												<span className="mr-1"><Fa className="fa-spin" icon={faSpinner} /></span>
												正在送出
											</>
										)}
									</Button>
								) : null}
							</Col>
							<Col>
								<div className="float-right mt-3">
									{showSubmittedBadge ? (
										<Toast>
											<ToastHeader icon="success">
												已送出
											</ToastHeader>
											<ToastBody>
												成績已送出，成績出現底色即表示送出成功
											</ToastBody>
										</Toast>
									) : null}
								</div>
							</Col>
						</Row>
						<Row className="text-center sticky-top mt-1 ml-2 / show-on-print print-header">
							{' '}
							{' '}
							{(deptSeeTeacher || query?.proxy) ? (
								<></>
							) : <>{userState.name || userState.user}</>}
							{' '}
							老師 /
							{' '}
							{currentDept}
							{' '}
							/ 列印日期：
							{dayjs().format('YYYY-MM-DD')}
						</Row>
						<Row className="text-center / show-on-print">
							<Col xs={4}>
								確認後請簽署並繳交系辦
							</Col>
							<Col xs={4}>
								簽署日期 _________________
							</Col>
							<Col xs={4}>
								簽署姓名 _________________
							</Col>
						</Row>
					</Container>
					<Modal isOpen={isNdaOn && !userState.nda && tempUserGroup === USER_GROUP.TEACHER}>
						<ModalHeader>保密協定</ModalHeader>
						<ModalBody>
							<div className="bg-white border border-gray px-2 py-4">
								<h2>試務資料保密暨利益迴避聲明書</h2>
								<br />
								<p className="indent2">
									<pre style={{ whiteSpace: 'pre-wrap' }}>
										{ndaContent}
									</pre>
								</p>
								<br />
								<br />
								<div className="text-right">
									立約人
									{' '}
									{userState.name || userState.user}
								</div>
								<div className="text-center">
									{dayjs().format('YYYY') - 1911}
									{' '}
									年
									{' '}
									{dayjs().format('MM')}
									{' '}
									月
									{' '}
									{dayjs().format('DD')}
									{' '}
									日
								</div>
							</div>
							<div className="text-muted mt-2">
								<small>
									<Fa icon={faInfoCircle} />
									{' '}
									按下
									<strong>同意</strong>
									即表示同意保密協定，
									<strong>系統將會記錄此行為。</strong>
								</small>
							</div>
							{/* 請詳讀
							{' '}
							<a href="/保密協定.pdf">保密協定</a>
							，列印簽名後始可使用本系統。
							<br />
							按下
							<strong>同意</strong>
							即表示同意保密協定，
							<strong>系統將會記錄此行為</strong>
							。 */}
						</ModalBody>
						<ModalFooter>
							<Button color="primary" onClick={() => { agreeTos(); setShowVideo(true); }}>同意</Button>
							{' '}
							<Button color="danger" onClick={() => { Router.back(); }}>不同意</Button>
						</ModalFooter>
					</Modal>
					<Modal isOpen={false && showVideo} size="lg">
						<ModalHeader>教學影片</ModalHeader>
						<ModalBody className="text-center">
							<ReactPlayer url="/guide.mp4" playing loop controls width="100%" />
						</ModalBody>
						<ModalFooter>
							<Button color="primary" onClick={() => { setShowVideo(false); }}>關閉影片</Button>
						</ModalFooter>
					</Modal>
				</>
			) : <LoadingTransition /> }
			<style jsx global>
				{`
				input::-webkit-outer-spin-button,
				input::-webkit-inner-spin-button {
					/* display: none; <- Crashes Chrome on hover */
					-webkit-appearance: none;
					margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
				}
				input[type=number] {
					-moz-appearance:textfield; /* Firefox */
				}
				td {
					vertical-align: middle !important;
				}
				td input {
					padding: 0.5px 1px !important;
					text-align: center;
					vertical-align: bottom
				}
				.trow {
					page-break-inside: avoid;
				}
				`}
			</style>
			<style>
				{`
				.app {
					background: #f9f9fb;
					min-height: 100vh;
				}
				.bg-that {
					background: #f9f9fb;
				}
				.show-on-print {
					display: none;
				}
				.indent2 {
					text-indent: 2rem;
				}
				@page {
					size: 210mm 297mm;
					margin: 13mm 13mm 13mm 13mm;
				}
				@media print {
					.app, bg-that {
						background: #fff;
					}
					.hide-on-print {
						display: none;
					}
					.show-on-print {
						display: flex;
					}
					.print-header {
						position: fixed;
						top: 0;
						left: 0;
					}
					.input {
						border: 0;
						background: transparent !important;
					}
					table {
						text-align: center;
						border: 1px solid #121212;
					}
					.table td, .table th {
						padding: 0px;
						border: 1px solid #121212 !important;
					}
					.table th {
						padding-top: 1.5rem;
					}
				}
				`}
			</style>
		</>
	);
};

const LoadingTransition = () => (
	<div className="ph-item gay">
		<Container fluid className="app2">
			<Row className="pt-5">
				<Col>
					<h2>成績登錄</h2>
				</Col>
			</Row>
			<Row className="my-2">
				<Col sm={4} xs={12}>
					<div className="ph-row">
						<div className="ph-col-12 big" />
					</div>
				</Col>
				<Col sm={{ size: 4, offset: 2 }} lg={{ size: 2, offset: 4 }}>
					<div className="ph-row">
						<div className="ph-col-8 big" />
					</div>
				</Col>
				<Col sm={{ size: 4 }} lg={{ size: 2 }}>
					<div className="ph-row">
						<div className="ph-col-8 big" />
					</div>
				</Col>
			</Row>
			<Row>
				<Col>
					<Table>
						<thead>
							<tr>
								<th className="sticky-top / bg-that2">
									<div className="ph-row">
										<div className="ph-col-8" />
									</div>
								</th>
								<th className="sticky-top / bg-that2">
									<div className="ph-row">
										<div className="ph-col-8" />
									</div>
								</th>
								<th className="sticky-top / bg-that2">
									<div className="ph-row">
										<div className="ph-col-8" />
									</div>
								</th>
								{Array(3).fill(' ').map((x, i) => (
									<th className="sticky-top / bg-that2" key={i} style={{ maxWidth: `${50}px` }}>{x}</th>
								))}
								<th className="sticky-top / bg-that2">
									<div className="ph-row">
										<div className="ph-col-8" />
									</div>
								</th>
								<th className="sticky-top / bg-that2">
									<div className="ph-row">
										<div className="ph-col-8" />
									</div>
								</th>
								<th className="sticky-top / bg-that2">
									<div className="ph-row">
										<div className="ph-col-8" />
									</div>
								</th>
							</tr>
						</thead>
						<tbody>
							{Array(50).fill({}).map((x, i) => (
								<tr key={i}>
									<td>
										<div className="ph-row">
											<div className="ph-col-8" />
										</div>
									</td>
									<td>
										<div className="ph-row">
											<div className="ph-col-12" />
										</div>
									</td>
									<td>
										<div className="ph-row">
											<div className="ph-col-4" />
										</div>
									</td>
									{Array(3).fill(' ').map(() => (
										<td style={{ maxWidth: `${50}px` }} key={Math.random()}>
											<div className="ph-row">
												<div className="ph-col-6" />
											</div>
										</td>
									))}
									<td>
										<div className="ph-row">
											<div className="ph-col-6 big" />
										</div>
									</td>
									<td>
										<div className="ph-row">
											<div className="ph-col-12 big" />
										</div>
									</td>
									<td>
										<div className="ph-row">
											<div className="ph-col-6 big" />
										</div>
									</td>
								</tr>
							))}
						</tbody>
					</Table>
				</Col>
			</Row>
			<style jsx global>
				{`
				.ph-item {
					display: block;
				}
				`}
			</style>
			<style jsx>
				{`
				.bg-that2 {
					border-top: 0;
					background: #fff !important;
				}
				.app2 {
					background: #fff !important;
				}
				`}
			</style>

		</Container>
	</div>
);

export default dynamic(() => Promise.resolve(DefaultPage), { ssr: false });
